$main = $('.js-main');
$menu = $('#menu');
$menuIcon = $('.mobile-menu-icon');
$section = $('.section-js');
$mobileMenu = $('.mobile-menu');
$header = $('#header');
$menuitem = $('.menu-item');



function menu() {

  $menuIcon.on('click', function(e) {
    $($header).toggleClass("open");
    // $($menuitem).toggleClass("fade-in-from-right");

    $($mobileMenu).toggleClass("open");
    $($main).toggleClass("menu-open");
    $(nav).removeClass("hide");
    $('body').toggleClass("overflow-hidden");
    e.preventDefault()

    // $menuIcon.toggleClass("open");
    // $main.toggleClass("exit-animation");

  });
}

menu();

function projectdetails() {

  $('.details-btn').on('click', function(e) {
    $(this).toggleClass("open");
    // $('.details').toggleClass("hidden");
    // $($main).toggleClass("menu-open");
    // $(nav).removeClass("hide");

    // $menuIcon.toggleClass("open");
    // $main.toggleClass("exit-animation");

  });
}

projectdetails();


function desktopmenu() {

  $('.desktop-menu .plus').on('click', function(e) {
    $('.desktop-menu').toggleClass("open");
    // $('.details').toggleClass("hidden");
    // $($main).toggleClass("menu-open");
    // $(nav).removeClass("hide");

    // $menuIcon.toggleClass("open");
    // $main.toggleClass("exit-animation");

  });
}

desktopmenu();



function animations() {

  var $section = $('.a-section')

  $section.each(function(index, element) {
    var wrapper = $(this);
    var topWatcher = scrollMonitor.create(wrapper, {
      top: -250

    });

    var bottomWatcher = scrollMonitor.create(wrapper, {
      bottom: 0

    });

    if ($(this).hasClass('full-reveal')) {

      topWatcher.fullyEnterViewport(function() {
        wrapper.addClass('start-animation');
        wrapper.removeClass('opacity-0');
      });


    } else if ($(this).hasClass('bottom-reveal')) {

      bottomWatcher.enterViewport(function() {
        wrapper.addClass('start-animation');
        wrapper.removeClass('opacity-0');


      });


    } else {

      topWatcher.enterViewport(function() {
        wrapper.addClass('start-animation');
        wrapper.removeClass('opacity-0');


      });

    }
  });

}

animations();

// function parallax() {
//
//
//   $(window).scroll(function() {
//     var scrollTop = $(window).scrollTop(),
//       logo = $('.logo-bg'),
//       logo_offset = logo.offset()
//
//
//
//
//     var $parallax_container = $('.parallax_container');
//
//     $parallax_container.each(function() {
//
//       var container_offset = $(this).offset(),
//         object_offset = $(this).find('.parallax').offset(),
//         h_start = scrollTop - container_offset.top,
//         v_start = scrollTop;
//
//
//       var left = h_start / -10 + 'px';
//       var right = h_start / 10 + 'px';
//       var top = v_start / -15 + 'px';
//       var bottom = v_start / 15 + 'px';
//
//
//       $(this).find('.parallax-left').css('transform', 'translate3d(' + left + ', 0 ,0)');
//       $(this).find('.parallax-right').css('transform', 'translate3d(' + right + ', 0 ,0)');
//       $(this).find('.parallax-top').css('transform', 'translate3d(0, ' + top + ' ,0)');
//       $(this).find('.parallax-bottom').css('transform', 'translate3d(0, ' + bottom + ' ,0)');
//
//     })
//   })
//
//
// }
//
// parallax();


function carousel() {

  $(document).ready(function() {
    $('.owl-carousel.gallery').owlCarousel({
      autoWidth: true,
      responsiveClass: true,
      dots: false,
      nav: false,
      loop: false,

      responsive: {
        0: {
          // items: 1,
        },
        600: {
          // items: 2,
        },
        1000: {
          // items: 3,
        }
      }
    })
  });

  $(document).ready(function() {
    $('.owl-carousel.full-carousel').owlCarousel({
      autoWidth: false,
      responsiveClass: true,
      dots: true,
      dotsData: false,
      nav: true,
      dotsClass: 'page-padding-x owl-dots',
      animateOut: 'fade-in-from-top',
      animateOut: 'fade-out',
      margin: 0,

      loop: true,
      dotsSpeed: '250',
      autoplayHoverPause: true,
      autoplay: false,


      responsive: {
        0: {
          items: 1,
          nav: true
        },
        600: {
          items: 1,
          nav: true
        },
        1000: {
          items: 1,
          nav: true,
          dots: true,
        }
      }
    });
  });

}

carousel();



// var animation = lottie.loadAnimation({
//   container: document.getElementById('eye'),
//   renderer: 'svg',
//   loop: true,
//   autoplay: false,
//   path: animation_load // the path to the animation json
// })


function nav() {

  var prev = 0;
  var $window = $(window);

  $(document).ready(function() {

    $window.on('scroll', function() {

      var scrollTop = $window.scrollTop();

      if (!$($main).hasClass("menu-open")) {

        if (scrollTop > 600) {
          $header.addClass('scrolled');
        } else {
          $header.removeClass('scrolled');

        }

        if (scrollTop > 1) {
          $header.toggleClass('hide', scrollTop > prev);
          prev = scrollTop;
        }



      }


    })

  })

}
nav();


function accordian() {

  $trigger = $('.accordian-trigger');

  $trigger.each(function() {

    // $accordian_trigger = '#' + $(this).attr('data-trigger');
    // $accordian_container = $accordian_trigger + '-container';


    $(this).click(function() {

      $accordian_container = '#' + $(this).attr('id') + '-container';
      $($accordian_container).toggleClass('closed')

      // console.log('yes ' + $accordian_container);

    })

  })
}
accordian()

$(document).ready(function() {

  $('a').each(function() {

    if ($(this).hasClass('scrollLink')) {

      $(this).on('click', function(e) {

        $(document).on("scroll", onScroll);
        e.preventDefault();
        $(document).off("scroll");

        var target = this.hash,
          menu = target;
        $target = $(target);
        $('html, body').stop().animate({
          'scrollTop': $target.offset().top + 2
        }, 500, 'swing', function() {
          window.location.hash = target;
          $(document).on("scroll", onScroll);
        });

      })


    } else if ($(this).attr('target') == '_blank') {


    } else if ($(this).hasClass('phone')) {

    } else if ($(this).hasClass('play-button')) {

    } else if ($(this).hasClass('pause-button')) {

    } else {

      $(this).on('click', function(e) {

        var href = $(this).attr("href");


        if (href == "#0") {
          return false;
        }

        $('body').addClass('clicked');

      })

    }
  })

})


// var video = document.getElementById('vimeo');
//
// $(video).find('.play-button').click(function() {
//
//   $('#vimeo_test').play();
//
// })


$('.main-video-container').each(function(index, element) {

  $(element).each(function() {

    var $id = $(this).attr('data-title');


    var ppbutton = document.getElementById($id + "_play-button");
    var mainVideo = document.getElementById($id + "_video");
    var prevVideo = document.getElementById($id + "_preview");

    ppbutton.addEventListener("click", playPause);

    console.log(element);

    mainVideo.addEventListener('ended',end,false);
    function end(e) {
      console.log("ENDED");

      $(prevVideo).addClass('block').removeClass('hidden');

      $(ppbutton).find('.btn_text').text('Play');

      $(ppbutton).addClass("opacity-100 hover").removeClass("opacity-0");

      $(ppbutton).hover(

        function() {

          $(this).addClass("opacity-100 hover").removeClass("opacity-0");

        },
        function() {

          $(this).addClass("opacity-100").removeClass("opacity-0 hover");



        }
      );

        // What you want to do after the event
    }


    function playPause() {

      if (mainVideo.paused) {

        $('.main-video').not(mainVideo).each(function() {
          this.pause();
          // $(this).find('.play-button .btn_text').text('Play');
          $(this).parent().parent().find('.btn_text').text('Play');
          // console.log($(this).parent().parent());

        });
        mainVideo.play();
        // $(ppbutton).text("Pause");

        $(ppbutton).find('.btn_text').text('Pause');

        $(prevVideo).addClass('hidden').removeClass('block');


        $(ppbutton).hover(

          function() {

            $(this).addClass("opacity-100 hover").removeClass("opacity-0");

          },
          function() {

            $(this).addClass("opacity-0").removeClass("opacity-100 hover");

          }
        );


      }
      else {
        mainVideo.pause();

        $(prevVideo).addClass('block').removeClass('hidden');

        $(ppbutton).find('.btn_text').text('Play');

        $(ppbutton).addClass("opacity-100 hover").removeClass("opacity-0");

        $(ppbutton).hover(

          function() {

            $(this).addClass("opacity-100 hover").removeClass("opacity-0");

          },
          function() {

            $(this).addClass("opacity-100").removeClass("opacity-0 hover");



          }
        );

      }
    }

  })
})

// + + + + + + + + FOR SCREEN CAPTURES + + + + + + + + + + + + + + +
//
// function pagescroll() {
//
//   var $sec = $('.a-section');
//
//   var window_height = $(window).height() - 150;
//   var doc_height = $(document).height();
//   var $set_top = doc_height / count;
//   var count = Math.floor(doc_height / window_height);
//
//
//   var iteration = []
//
//   for (var i = 1; i < count; i++) {
//
//     iteration.push(window_height * i)
//
//   }
//
//   $(iteration).each(function(index, element) {
//
//     $(document).ready(function() {
//       $('body,html').delay(500).animate({
//         scrollTop: iteration[index]
//       }, 2000, 'easeInOutQuart');
//     })
//
//
//   })
//
//   console.log(doc_height, iteration);
//
// }
//
//
// setTimeout(pagescroll,15)



jQuery.easing['jswing'] = jQuery.easing['swing'];

jQuery.extend( jQuery.easing,
{
    def: 'easeOutQuad',
    swing: function (x, t, b, c, d) {
        //alert(jQuery.easing.default);
        return jQuery.easing[jQuery.easing.def](x, t, b, c, d);
    },
    easeInQuad: function (x, t, b, c, d) {
        return c*(t/=d)*t + b;
    },
    easeOutQuad: function (x, t, b, c, d) {
        return -c *(t/=d)*(t-2) + b;
    },
    easeInOutQuad: function (x, t, b, c, d) {
        if ((t/=d/2) < 1) return c/2*t*t + b;
        return -c/2 * ((--t)*(t-2) - 1) + b;
    },
    easeInCubic: function (x, t, b, c, d) {
        return c*(t/=d)*t*t + b;
    },
    easeOutCubic: function (x, t, b, c, d) {
        return c*((t=t/d-1)*t*t + 1) + b;
    },
    easeInOutCubic: function (x, t, b, c, d) {
        if ((t/=d/2) < 1) return c/2*t*t*t + b;
        return c/2*((t-=2)*t*t + 2) + b;
    },
    easeInQuart: function (x, t, b, c, d) {
        return c*(t/=d)*t*t*t + b;
    },
    easeOutQuart: function (x, t, b, c, d) {
        return -c * ((t=t/d-1)*t*t*t - 1) + b;
    },
    easeInOutQuart: function (x, t, b, c, d) {
        if ((t/=d/2) < 1) return c/2*t*t*t*t + b;
        return -c/2 * ((t-=2)*t*t*t - 2) + b;
    },
    easeInQuint: function (x, t, b, c, d) {
        return c*(t/=d)*t*t*t*t + b;
    },
    easeOutQuint: function (x, t, b, c, d) {
        return c*((t=t/d-1)*t*t*t*t + 1) + b;
    },
    easeInOutQuint: function (x, t, b, c, d) {
        if ((t/=d/2) < 1) return c/2*t*t*t*t*t + b;
        return c/2*((t-=2)*t*t*t*t + 2) + b;
    },
    easeInSine: function (x, t, b, c, d) {
        return -c * Math.cos(t/d * (Math.PI/2)) + c + b;
    },
    easeOutSine: function (x, t, b, c, d) {
        return c * Math.sin(t/d * (Math.PI/2)) + b;
    },
    easeInOutSine: function (x, t, b, c, d) {
        return -c/2 * (Math.cos(Math.PI*t/d) - 1) + b;
    },
    easeInExpo: function (x, t, b, c, d) {
        return (t==0) ? b : c * Math.pow(2, 10 * (t/d - 1)) + b;
    },
    easeOutExpo: function (x, t, b, c, d) {
        return (t==d) ? b+c : c * (-Math.pow(2, -10 * t/d) + 1) + b;
    },
    easeInOutExpo: function (x, t, b, c, d) {
        if (t==0) return b;
        if (t==d) return b+c;
        if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
        return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
    },
    easeInCirc: function (x, t, b, c, d) {
        return -c * (Math.sqrt(1 - (t/=d)*t) - 1) + b;
    },
    easeOutCirc: function (x, t, b, c, d) {
        return c * Math.sqrt(1 - (t=t/d-1)*t) + b;
    },
    easeInOutCirc: function (x, t, b, c, d) {
        if ((t/=d/2) < 1) return -c/2 * (Math.sqrt(1 - t*t) - 1) + b;
        return c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b;
    },
    easeInElastic: function (x, t, b, c, d) {
        var s=1.70158;var p=0;var a=c;
        if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
        if (a < Math.abs(c)) { a=c; var s=p/4; }
        else var s = p/(2*Math.PI) * Math.asin (c/a);
        return -(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
    },
    easeOutElastic: function (x, t, b, c, d) {
        var s=1.70158;var p=0;var a=c;
        if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
        if (a < Math.abs(c)) { a=c; var s=p/4; }
        else var s = p/(2*Math.PI) * Math.asin (c/a);
        return a*Math.pow(2,-10*t) * Math.sin( (t*d-s)*(2*Math.PI)/p ) + c + b;
    },
    easeInOutElastic: function (x, t, b, c, d) {
        var s=1.70158;var p=0;var a=c;
        if (t==0) return b;  if ((t/=d/2)==2) return b+c;  if (!p) p=d*(.3*1.5);
        if (a < Math.abs(c)) { a=c; var s=p/4; }
        else var s = p/(2*Math.PI) * Math.asin (c/a);
        if (t < 1) return -.5*(a*Math.pow(2,10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )) + b;
        return a*Math.pow(2,-10*(t-=1)) * Math.sin( (t*d-s)*(2*Math.PI)/p )*.5 + c + b;
    },
    easeInBack: function (x, t, b, c, d, s) {
        if (s == undefined) s = 1.70158;
        return c*(t/=d)*t*((s+1)*t - s) + b;
    },
    easeOutBack: function (x, t, b, c, d, s) {
        if (s == undefined) s = 1.70158;
        return c*((t=t/d-1)*t*((s+1)*t + s) + 1) + b;
    },
    easeInOutBack: function (x, t, b, c, d, s) {
        if (s == undefined) s = 1.70158;
        if ((t/=d/2) < 1) return c/2*(t*t*(((s*=(1.525))+1)*t - s)) + b;
        return c/2*((t-=2)*t*(((s*=(1.525))+1)*t + s) + 2) + b;
    },
    easeInBounce: function (x, t, b, c, d) {
        return c - jQuery.easing.easeOutBounce (x, d-t, 0, c, d) + b;
    },
    easeOutBounce: function (x, t, b, c, d) {
        if ((t/=d) < (1/2.75)) {
            return c*(7.5625*t*t) + b;
        } else if (t < (2/2.75)) {
            return c*(7.5625*(t-=(1.5/2.75))*t + .75) + b;
        } else if (t < (2.5/2.75)) {
            return c*(7.5625*(t-=(2.25/2.75))*t + .9375) + b;
        } else {
            return c*(7.5625*(t-=(2.625/2.75))*t + .984375) + b;
        }
    },
    easeInOutBounce: function (x, t, b, c, d) {
        if (t < d/2) return jQuery.easing.easeInBounce (x, t*2, 0, c, d) * .5 + b;
        return jQuery.easing.easeOutBounce (x, t*2-d, 0, c, d) * .5 + c*.5 + b;
    }
});
